#form.CandidateFunnelForm {
    padding-bottom: 120px;
}
.CandidateFunnelButtonGroupContainer {
    text-align: center;
    z-index: 999;
}
.CandidateFunnelButtonGroup {
    margin: 15px auto;
}
.CandidateFunnelButtonGroup .btn {
    border-color: rgba(0,0,0,0.5);
    color: rgba(0,0,0,0.5);
    border-radius: 20px;
    background: rgba(255,255,255,0.75);
}

.CandidateFunnelButtonGroup .btn:hover {
    color: #fff;
    background-color: rgba(0,0,0,0.5);
    border-color: rgba(0,0,0,0.5);
}


.CandidateFunnel.container {
    width: 100%;
    max-width: 100%!important;
    min-height: 100%;
    overflow-y: scroll;
    background: #fff;
    position: absolute; /* fixed */
    z-index: 99;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 0px;
}

.CandidateFunnelContent.LimitedWidth.container {
    max-width: calc(768px + 60px)!important;
}

.CandidateFunnelContainer .CandidateFunnel.container {
    position: absolute;
}


.CandidateFunnelContainer,
.CandidateFunnelContainer.container {
    /* border: 1px #e0e6e8 solid;
    background: #fff; */
    border-radius: 6px;
    width: 100%;
    /* max-width: 720px; */
    min-height: 800px;
    padding: 0px;
    margin-bottom: 15px;
    margin: auto;
    text-align: left;
    position: relative;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    scroll-behavior: smooth;
}

/* AD */
.FeedAd {
    box-shadow: 0px 3px 8px rgba(0,0,0,0.25);
    width: 100%;
    max-width: 480px;
    margin: auto;
    border-radius: 6px;
    background: #fff;
}
.FeedAd .AdImage {
    border-radius: 0px;
}
.StoryAd {
    width: 100%;
    max-width: calc(480px * 0.8);
    margin: auto;
    position: relative;
}
.AdHeaderLogo {
    float: left;
    width: 40px;
    padding: 5px;
    border: 1px solid #eee;
    border-radius: 50%;
    margin-right: 15px;
    margin-top: -5px;
    background: #fff;
}
.AdHeader {
    width: 480px;
    margin: auto;
    padding: 20px 15px 10px 15px;
}
.AdFooter {
    width: 480px;
    margin: auto;
    padding: 15px 15px 15px 15px;
}
.AdFooterUrl {
    color: #4f6c7b;
    font-size: 12px;
    margin: 0px;
}
.AdTitle {
    font-size: 15px;
    line-height: 1;
    margin: 0px;
}
.AdSponsored {
    color: #4f6c7b;
    font-size: 12px;
}
.AdHeadline {
    padding: 10px;
    clear: both;
    display: block;
}
.StoryAd .AdHeader {
    position: absolute;
    z-index: 99;
}
.StoryAd .AdHeader .AdTitle {
    color: #fff;
    margin-top: 2px;
}
.StoryAd .AdHeader .AdHeaderLogo {
    width: 30px;
    margin-right: 8px;
}


.AdImage {
    width: 100%;
    max-width: 480px;
    height: 480px;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
    display: block;
    background-position: center center;
    background-size: cover;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.CandidateFunnelContent.story .AdImage,
.CandidateFunnelContent.story.container .AdImage {
    width: calc(480px * 0.8);
    height: calc(480px * 1.5);
}



.AdImage #background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}
  
.AdImageOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.25);
    padding: 45px 40px;
    z-index: 9;
}
.AdImage.Two {
    background-size: 100% 50%;
    background-position: top;
    background-repeat: no-repeat;
}
.AdImage.Two #background-video {
    height: 50%;
}

.AdImageTitle {
    color: #fff;
    padding: 0px 0px 10px 0px;
    font-size: 26px;
    line-height: 1.5;
    margin-top: 50px;
}
.StoryAd .AdImageTitle {
    margin-top: 100px;
}
.AdImage.Two .AdImageTitle {
    margin-top: calc(50% - 80px + 15px);
}
.AdImageJobillaArea {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    background: #fff;
    height: 36px;
    width: 80px;
    display: block;
    border-top-left-radius: 6px;
}
.AdImageJobillaLogo {
    display: block;
    margin: auto;
    height: 36px;
    padding: 3px;
}
.FeedAd .AdMeta {
    margin: 10px 0px 60px 0px;
}
.StoryAd .AdMeta {
    margin-bottom: 100px;
}
.AdMeta span {
    color: #eee;
    font-size: 15px;
    display: inline-block;
    margin-right: 15px;
}
.btn.btn-ad {
    color: #fff!important;
    margin: 10px 0px;
    padding: 10px 20px;
    background: #000;
}
.btn.BtnSubmitContact {
    color: #fff!important;
    margin: 10px 0px;
    padding: 10px 40px;
    background: #000;
    box-shadow: 0 4px 3px rgb(0 0 0 / .1);
}
.Black {
    background: #000;
}
.Purple {
    background: #4A00E0;
}

.Green {
    background: #198754;
}
.Red {
    background: #dc3545;
}

/* WELCOME */
.WelcomeHeader {
    max-width: 1200px;
    margin: auto;
    background: #fff;
}
.ResultsBanner, 
#conversion-page, 
.WelcomePage {
    width: 480px;
    height: 800px;
    background: #fff;
    margin: auto;
    border-radius: 5px;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow-y: scroll;
}
.WelcomeBanner {
    width: 100%;
    height: auto;
    display: block;
    background-position: center center;
    background-size: cover;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.25);
    margin-top: 30px;
    margin-bottom: -30px;
    /* border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; */
    border-radius: 6px;
    overflow: hidden;
}
.WelcomeBannerOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.25);
    padding: 45px 40px;
    min-height: 360px;
}
.WelcomeTitleContainer{
    padding: 30px 60px;
}
.WelcomeTitleContainer .AdMeta {
    margin-top: 0px;
}
.WelcomeTitleContainer .AdMeta span {
    color: #4f6c7b;
}

.WelcomeTitle {
    /* color: #fff;
    text-align: center; */
    padding: 60px 0px 0px 0px;
    font-size: 26px;
    line-height: 1.25;
}
.WelcomeSubTitle {
    display: block;
    margin-top: 20px;
    font-size: 20px;
    line-height: 24px;
}

.WelcomeBanner .AdMeta {
    text-align: center;
}

.WelcomeBanner.Two .WelcomeTitle {
    text-align: left;
    color: #111;
}
.WelcomeBanner.Two .AdMeta {
    text-align: left;
}
.WelcomeBanner.Two .AdMeta span {
    color: #4f6c7b;
}
.WelcomeBanner.Two .title-col {
    padding: 30px 45px;
}
.WelcomeBanner.Two .image-background {
    height: 300px;
    background-size: cover!important;
}

.WelcomeCTA {
    padding: 10px 0px;
}
.WelcomeCTA .btn {
    font-weight: 700;
    font-size: 16px!important;
    padding: 10px 40px;
    box-shadow: 0 4px 3px rgb(0 0 0 / .1);
}
.WelcomeContentContainer {
    width: 100%;
    min-height: 600px;
    background-color: rgba(243, 244, 246, 1);
    padding: 30px 30px;
}
.WelcomeContent {
    color: rgb(69, 69, 69);
    text-align: justify;
    width: 100%;
    max-width: 920px;
    margin: auto;
    line-height: 1.75;
    font-size: 15px;
    padding: 30px 30px;
}
.WelcomeContent ul {
    margin-left: 40px;
}
.WelcomeContent ul li {
    padding: 3px;
}
.WelcomePageContent {
    background-color: rgba(243, 244, 246, 1);
    color: #666;
    text-align: justify;
    width: 100%;
    display: block;
    margin: auto;
    line-height: 1.75;
    font-size: 16px;
    border: 0px;
    min-height: 400px;
    padding: 15px 0px;
}
.WelcomeContent h6 {
    color: #444;
}
.WelcomeFooter {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 10px 15px;
    color: #aaa;
    font-size: 12px;
}
.WelcomeFooterLogo {
    display: inline-block;
    width: 30px;
    padding: 5px;
    /* background: #fff; */
    margin-top: -4px;
}


/* CONVERSION */
.QuestionTitleContainer {
    display: block;
    min-height: 56px;
    /* min-height: 36px; */
    margin-top: 45px;
    margin-bottom: 10px;
}
.QuestionOrderId {
    display: block;
    float: left;
    height: 32px;
    width: 32px;
    background-color: rgb(113, 13, 224);
    color: #fff;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 16px;
    margin-right: 15px;
}
.RequiredLabel {
    font-size: 12px;
    color: rgb(107 114 128 / 1);
    line-height: 32px;
    display: inline-block;
    padding: 0px 10px;
    font-weight: 400;
}

.Option.Radio input,
.Option.Multiselect input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.Option.Radio .Checkmark,
.Option.Multiselect .Checkmark {
    position: absolute;
    top: 10px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid rgb(113, 13, 224);
}
.Option.Radio .Checkmark {
    border-radius: 50%;
}
.Option.Multiselect .Checkmark {
    border-radius: 3px;
}

  
/* On mouse-over, add a grey background color */
.Option.Radio:hover input ~ .Checkmark,
.Option.Multiselect:hover input ~ .Checkmark {
    background-color: #eee;
}
  
/* When the radio button is checked, add a blue background */
.Option.Radio input:checked ~ .Checkmark,
.Option.Multiselect input:checked ~ .Checkmark {
    background-color: #fff;
}
  
/* Create the indicator (the dot/circle - hidden when not checked) */
.Option.Radio .Checkmark:after,
.Option.Multiselect .Checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the indicator (dot/circle) when checked */
.Option.Radio input:checked ~ .Checkmark:after,
.Option.Multiselect input:checked ~ .Checkmark:after {
    display: block;
}
  
/* Style the indicator (dot/circle) */
.Option.Radio .Checkmark:after {
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: rgb(113, 13, 224);
}
.Option.Multiselect .Checkmark:after {
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 3%;
    background: rgb(113, 13, 224);
}
  

.ConversionTitle {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
}
#bot,
#form {
    color: #454545;
    line-height: 1.5;
    padding: 0px 45px;
    max-width: 100%;
    margin: auto;

}
#form legend {
    font-size: 16px;
    font-weight: 600;
    float: left;
    display: block;
    width: calc(100% - 32px - 15px);
    line-height: 32px;
    margin-top: -5px;
}
#form .Option {
    margin-left: 50px;
    height: 45px;
    padding: 12px 10px 12px 24px;
    position: relative;
}
#form label {
    color: #555;
    display: inline-block;
    padding: 0px 10px;
}

#conversion-page.Bot {
    background: #f5f6fa;
}
.bot-question-holder {
    display: block;
    max-width: 600px;
    margin: 30px auto;
    position: relative;
}
.bot-question {
    display: inline-block;
    background: #fff;
    padding: 10px 20px;
    color: #555;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    margin-bottom: 10px;
    border: 1px solid #e0e6e8;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 4px 12px;
}
.bot-head {
    height: 30px;
    width: 30px;
    position: absolute;
    left: -36px;
    top: 10px;
}
.bot-options {
    text-align: right;
}
.bot-option {
    display: inline-block;
    border-radius: 12px;
    background: #4A00E0;
    color: #fff;
    padding: 8px 16px;
    margin-left: 5px;
    margin-bottom: 5px;
}
.bot-option:hover {
    cursor: pointer;
    text-decoration: underline;
}

.option-label.selected {
    font-weight: 600;
}

/* RESULTS */

.ResultsTitle {
    font-size: 24px;
    text-align: center;
    max-width: 80%;
    margin: auto;
    line-height: 1.25;
    padding: 30px;
}
.ResultsBanner {
    width: 100%;
    height: 300px;
    display: block;
    background-position: center center;
    background-size: cover;
}
.ResultsBannerOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.25);
    padding: 45px 40px;
}
.ResultsTitle {
    text-align: center;
    padding: 15px 15px;
    font-size: 26px;
    line-height: 1.5;
}
.ResultsContent {
    color: #333;
    text-align: center;
    line-height: 1.5;
    padding: 30px 30px;
}


.ResultsContainer {
    text-align: center;
    padding: 60px;
}

.ResultsStars {
    padding: 15px;
    font-size: 24px;
}
.ResultsFeedback {
    max-width: 480px;
    margin: auto;
}
.BotInput {
    min-width: 60%;
}

.BotInput .TextInput {
    background: none!important;
    border: none!important;
    border-bottom: 1px #fff solid!important;
    border-radius: 0px;
    color: #fff;
    font-size: 16px;
    width: 90%;
    float: left;
    display: block;
    margin-bottom: 0px;
}
.BotInput svg {
    font-size: 20px;
    line-height: 40px;
    margin-top: 10px;
}
@import url(https://use.typekit.net/ayh7njc.css);  
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* COLORS
Background: HEX: #f8f9fc HSL hsl(225deg 40% 98.04%)
Dark purple: HEX: #390671 HSL: hsl(268.6deg 89.92% 23.33%)


*/


* {
    padding: 0px;
    margin: 0px;
    transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease;
}
html {
    scroll-behavior: smooth;
}
html, body {
    height: 100%
}
a {
    color: #4A00E0;
}
#app,
.AppContainer {
    min-height: 100%
}
.AppContainer {
    min-height: 1080px;
    padding-bottom: 60px;
}
body {
	min-height: 100%;
	/* font-family: hero-new, 'Hind', sans-serif!important; */
    font-family: 'Onest', sans-serif!important;
	color: #333;
	font-size: 14px;
    background: #fff;
    /* background: #4A00E0; */
	/* background: #f8f9fc; */
}
#app {
    /* background-color: #eff3f8;
    background-color: #eefbff; */
    /* background-color: #eff5f7;
    background-color: #f7fafb; */
    background-color: #f8f9fc;
    /* background-color: #fff; */
}
.Main.container {
    padding-top: 80px;
    margin-left: 64px;
    margin: auto;
    max-width: 1280px;
}
.MainWithHero.container {
    padding-top: 30px;
}
.MainWithHeroSecondary.container {
    /* padding-top: calc(80px + 30px); */
    padding-top: calc(0px + 60px + 80px)!important;
}
.MainWithNavigator.container {
    max-width: calc(100% - 64px - 250px);
    margin-left: calc(64px + 250px);
    padding: 60px 30px 0px 15px;
}
.MainWithNav.container {
    /* padding-top: calc(80px + 30px + 55px); */
    /* padding-top: calc(80px + 30px + 15px); */
    padding-top: calc(30px + 15px);
}
.FullWidth.container {
    max-width: calc(100% - 64px);
    margin-left: 64px;
    padding: 0px;
    /* padding-top: 80px; */
    padding-top: 0px;
}
/* .FullWidthAdmin.container {
    /* padding-top: 80px; */

.Main h1,
.MainWithNavigator h1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0px 20px -30px;
    position: absolute;
    top: 0px;
    padding: 30px 0px;
    color: #390671;
}
.Main h2,
.MainWithNavigator h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0px 10px 0px;
}
.Main h3,
.MainWithNavigator h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0px 10px 0px;
}
.Main h4,
.MainWithNavigator h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0px 10px 0px;
}
.HomeContent {
    margin-top: 220px;
    margin-top: 20px;
}
.HeroSmall,
.HeroHome {
    background: #fff;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
    border-radius: 6px;
    width: 100%;
    padding: 20px 30px;
    background: #710de0;
    background: #390671;
    /* background-image: url('../img/jobilla_background_purple.png'); */
    background-position: center center;    
    /* background: -webkit-linear-gradient(to right, #4A00E0, #710de0)!important;
    background: linear-gradient(to right, #4A00E0, #710de0)!important;    */
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center -100px; */
    text-align: center;
    /* position: fixed; */
    /* top: -100px; */
    position: relative;
    top: 0px;
    width: 100%;
    left: 0%;
    /* transform: rotate(-3deg); */
    /* height: 320px; */
    height: 200px;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px -50px 36px -28px inset;
    background: #f4eefd;    
}
.HeroHome {
    margin-top: -50px;
    margin-bottom: 15px;
}
.HeroHomeBg {
    background-position: 50% 80%;
    /* filter: hue-rotate(-20deg) brightness(1.25); */
}
.HeroHomeBg {
    /* background-color: #6c00ef;
    background-image: url('../img/jobilla_background_purple.png'); */
    background-position: center center;    
    background-repeat: no-repeat;
    background-size: cover;
    /* background: url('https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2748&q=80'); */
    /* url('https://images.unsplash.com/photo-1679189789181-06448aa7c382?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2728&q=80');
    */
    background-position: 50% 30%;
    background-size: cover;

    background-color: #390671;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 30%;
    background-size: cover;
    /* border: 30px #fff solid; */
    /* border: 30px #eff3f8 solid; */
    /* margin: 0px 0px 0px 64px; */
    /* width: calc(100% - 64px); */
    border-radius: 24px;

}
.HeroHomeOverlay {
    max-width: 100%;
    margin: auto;
    background: rgba(0,0,0,0.2);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;

    max-width: 100%;
    margin: 0px -30px;
    background: url(https://7914397.fs1.hubspotusercontent-na1.net/hubfs/7914397/home%20page%20new/background_figures_new.svg);
    background-size: 90%;
    position: absolute;
    padding: 30px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-position: 0% -10%;
    border-radius: 20px;


}
.HeroHome.OldHeroHome {
    background: #f4eefd;    
}
.HeroHomeProfilePhoto {
    margin: 30px 20px 20px 0px;
    width: 80px;
    padding: 0px;
    border-radius: 50%;
    float: left;
    border: 2px rgba(239, 243, 248, 0.6) solid;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.25);
}

.HeroSecondary {
    background: #fff;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
    border-radius: 6px;
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 15px;
    background: #710de0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center -100px;
    text-align: center;
    position: fixed;
    top: 0px;
    width: 100%;
    left: 0%;
    height: 80px;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px -50px 36px -28px inset;
    background: #f4eefd;    

} 
.HeroSecondaryBg {
    background-color: #390671;
}
.HeroLead {
    color: #fff;
    font-size: 18px;
    padding: 0px 15px;
    text-align: left;
    margin-top: -5px;
}

.HeroSmall {
    top: 0px;
    height: 80px;
    z-index: 9;
    box-shadow: none;
    background: #390671;
    background-position: 50% 50%;
    /* filter: hue-rotate(-20deg) brightness(1.25); */
}
.HeroHome .row {
    max-width: 1280px;
    margin: auto;

}
.CampaignsAvailable,
.HeroHome h2 {
    font-weight: 600;
    text-align: left;
    font-size: 26px;
    color: #fff;
    padding: 30px 15px 0px 15px;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    /* transform: rotate(3deg); */
}
.HeroHome h4 {
    font-weight: 400;
    text-align: left;
    font-size: 18px;
    color: #fff;
    padding: 0px 30px 0px 30px;
    display: block;
    margin-top: -0px;
    margin-bottom: 15px;
    line-height: 1.5;
}
.Vision {
    background: #fff;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
    border-radius: 6px;
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 15px;
    background: #710de0;
    /* background: -webkit-linear-gradient(to right, #4A00E0, #710de0)!important;
    background: linear-gradient(to right, #4A00E0, #710de0)!important;    */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center -100px;
    text-align: center;
    position: fixed;
    /* top: -100px; */
    top: 0px;
    width: 100%;
    left: 0%;
    /* transform: rotate(-3deg); */
    height: 360px;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px -50px 36px -28px inset;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.CampaignsAvailable {
    color: #ddd;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    margin-top: 0px;
    margin-bottom: 30px;
}
.HomeOpens {
    /* transform: rotate(3deg); */
    margin-left: 15px;
    float: left;
}
.HeroHome.OldHeroHome h2 {
    color: #333;
}
.HeroHome.OldHeroHome .HomeOpen {
    color: #710de0;
}

.HomeOpen {
    display: inline-block;
    background:rgba(255,255,255,0.4);
    color: #fff;
    padding: 3px 12px;
    border-radius: 8px;
    margin-right: 10px;
    font-size: 14px;
    padding: 6px 18px;
    /* background: linear-gradient(90deg, rgba(154,119,202,1) 0%, rgba(120,84,172,1) 54%);
    color: #fff; */
}
.HomeOpen:hover {
    color: #fff;
}
.Box,
.InfoBox {
    background: #fff;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
    border-radius: 8px;
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 15px;
    text-align: center;
    border: 1px #e0e6e8 solid;
    transition: box-shadow 0.25s ease;
}
.Box {
    text-align: left;
}
.Box.DarkPurple {
    background: #390671;
}

.Box.DashboardBox {
    margin: auto;
    padding: 10px 0px 0px 0px;
}
.Boxlink h2 {
    color: #333;
}
.Box.DarkPurple .BoxHighlight,
.Box.DarkPurple h2,
.Box.DarkPurple h3,
.Box.DarkPurple h4 {
    color: #fff;
}
.Box.DarkPurple p {
    color: #f4ecfe;
}
.Box.TalentInsightsBox {
    background: #390671;
    border: 0px;
    box-shadow: none;
}
.Box.TalentInsightsBox h2 {
    color: #390671;
    color: #fff;
}
.Box.DarkPurple p {
    color: #495057;
    color: #dee2e6;
}
.Box.TalentInsightsBox a {
    /* color: #710cdf; */
    color: #dee2e6;
}

.Main h2.TalentInsightsDividerTitle {
    color: #390671;
    display: block;
    margin: 15px 0px;
}
.Box.TalentInsightsBox h4 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #20c997;
}
.Box.TalentInsightsBox h2 {
    font-size: 36px;
}
.Main h2.TalentInsightsCenteredTitle {
    text-align: center;
    font-size: 36px;
    color: #710cdf;
    margin: 30px 30px;
}
/* .TalentInsightsCenteredTitle::before {

} */
.TalentInsightsBoxDescription {
    font-size: 11px;
    font-style: italic;
}


.ProductVariationBox,
.TalentInsightsContentBox {
    position: relative;
    background-color: #f4ecfe;
    border: 1px #f4ecfe solid;
    padding: 30px 45px;
    border-radius: 20px;
    margin-bottom: 30px;
    transition: 0.2s ease all;
    overflow: hidden;
}
.ProductVariationBox.Disabled {
    background-color: #eee;
    border: 1px #eee solid;
}
.ProductVariationBox.Suggested {
    background-color: #390671;
    border: 1px #390671 solid;
}
.ProductVariationBox.White,
.TalentInsightsContentBox.White {
    background-color: #fff;
    border: 0px;
}
.TalentInsightsContentBox:hover {
    scale: 1.01;
}
.ProductVariationTitle,
.TalentInsightsContenTitle {
    text-transform: uppercase;
    color: #390671;
    color: #710ce2;
    font-size: 16px;
    font-weight: 700;
}
.ProductVariationBox.Disabled .ProductVariationTitle {
    color: #999;
}
.ProductVariationBox.Suggested .ProductVariationTitle {
    color: #fff;
}

.ProductVariationAvailability {
	display: inline-block;
	padding: 4px 12px;
	background-color: #20c997;
	border-radius: 12px;
	margin: -4px 0px;
    color: #fff;
    font-size: 18px;
}
.ProductVariationBox.Disabled .ProductVariationAvailability {
	background-color: #999;
}
.TalentInsightsContentBox h4 {
    color: #390671;
    font-size: 24px;
    text-align: center;
}
.ProductVariationBox h4 {
    color: #390671;
    font-size: 24px;
}
.ProductVariationBox.Disabled h4 {
    color: #999;
}
.ProductVariationBox.Suggested h4 {
    color: #fff;
}

.TalentInsightsContentBox h4.TalentInsightBoxCentered {
    text-align: center;
    margin-bottom: 30px;
    display: block;
}

.ProductVariationBox h4 span,
.TalentInsightsContentBox h4 span {
    font-size: 36px;
}
.TalentInsightsLightLink {
    color: #495057;
    color: #dee2e6;
}
.TalentInsightsLightLink:hover {
    color: #495057;
    color: #dee2e6;
    text-decoration: underline;
}
.TalentInsightsTableHead ,
.TalentInsightsTableHead tr,
.TalentInsightsTableHead th {
    background: none!important;
}


.FeasibilityBox {
    background-color: #20c997;
    background-color: #390671;
    text-align: center;
    padding: 60px 60px;
}
.FeasibilityBox.FeasibilityDifficult {
    background-color: #842029;
    background-color: #390671;
}
.FeasibilityBox h2 {
    color: #fff;
}
.FeasibilityBox .FeasibilityNumber {
    color: #fff;
    font-size: 48px;
    font-weight: 700;
}
.BoxPad {
    padding-left: 90px;
    padding-right: 90px;
}


.Boxlink,
.Boxlink:hover {
        color: #4f6c7b;
}
.Boxlink:hover {
    text-decoration: none;
}
.Boxlink:hover .Box {
    color: #333;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.15);
}
.Boxlink:hover .btn.btnAllRecruitments {
    padding: 8px 30px!important;
}
.Boxlink:hover .HomeBoxBackground {
    background-size: 105%;
}


.InfoBox.Purple {
    background: #710de0!important;
    background: -webkit-linear-gradient(to right, #4A00E0, #710de0)!important;
    background: linear-gradient(to right, #4A00E0, #710de0)!important;   
}
.InfoBox h4 {
    font-size: 16px;
}
.InfoBox.Bg h4,
.InfoBox.Purple h4 {
    color: #fff;
}
.InfoBox .btn {
    margin-top: 20px;
}
.Highlight {
    font-size: 24px;
    font-weight: 700;
    display: block;
    clear: both;
}
.BoxHighlight {
    font-size: 36px;
    font-weight: 700;
    display: block;
    clear: both;
}
.BoxHighlightSecondary {
    color: #999;
    font-weight: 400;
    font-size: 20px;
    vertical-align: middle;
    margin-left: 10px;
}
.InfoBox.Bg .Highlight,
.InfoBox.Purple .Highlight {
    color: #fff;
}
.TextLeft {
    text-align: left!important;
}
.notification-container {
    text-align: left!important;
}

.HomeBoxBackground {
    height: 180px;
    width: calc(100% + 60px);
    display: block;
    background-size: 100%;
    background-position: 50% 50%;
    border-radius: 8px 8px 0px 0px;
    margin: -20px -30px 20px -30px;
    transition: background-size 0.25s ease;
}
.HomeBoxBackgroundSmall {
    height: 90px;
}
@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.ModalWithAnimation {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 12em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.ModalAnimationWave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
}

.ModalAnimationWave:nth-of-type(3) {
    bottom: -2.5em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
}

@keyframes wave {
    2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }
}
.Loading {
    width: 60px;
    height: 60px;
    margin: 30px auto;
    background-size: cover;
    background-image: url("../../public/img/loading3.gif");
}
.LoadingPhase {
    text-align: center;
    font-size: 18px;
}
.LoadingAI {
    display: block;
    padding: 0px;
    float: right;
    width: 40px;
    height: 20px;
    margin: 0px auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../public/img/loading3.gif");
}
.LoadingCandidates {
    display: block;
    padding: 0px;
    width: 40px;
    height: 20px;
    margin: 0px auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../public/img/loading3.gif");
}
.DraftLogo {
    max-width: 140px;
    margin: auto;
    padding: 30px;
}
.RecruitmentAnalytics .InfoBox {
    width: 16.66%;
    float: left;
	box-shadow: none;
    text-align: left;
    border-radius: 0px;
    border-left: 1px #f4eefd solid;
    border-bottom: 1px #f4eefd solid;
    margin: 0px;
}
.RecruitmentAnalyticsSupport .InfoBox {
    border-bottom: 0px;
}
.RecruitmentAnalytics .InfoBox:first-of-type {
    border-left: 0px;
}
.RecruitmentAnalyticsBox {
    display: block;
    overflow: hidden;
    min-height: 200px;
    border-radius: 8px;
    position: relative;
    background: #fff;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
    margin-bottom: 30px;
    border: 1px #e0e6e8 solid;
}
.InfoBoxLead {
    font-weight: 700;
    color: #4f6c7b;
}
.Conversion {
    display: block;
    color: #4f6c7b;
    font-size: 16px;
    font-weight: 700;
}
.RichTextEditorContainer {
	box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 15px;
}
.CommentBubble {
    padding: 0px!important;
    overflow: hidden;
}
.CommentBubble .RichTextEditorContainer {
	box-shadow: none;
    background: #fff;
    border-radius: 0px;
    overflow: hidden;
    margin-bottom: 0;
}

.RichTextEditorContainer div[role="textbox"] {
    padding: 0px 15px;
}
.RichTextEditorContainer div[title="Left"],
.RichTextEditorContainer div[title="Center"],
.RichTextEditorContainer div[title="Right"],
.RichTextEditorContainer div[title="Embedded"],
.RichTextEditorContainer div[title="Strikethrough"],
.RichTextEditorContainer div[title="Superscript"],
.RichTextEditorContainer div[title="Subscript"],
.RichTextEditorContainer div[title="Justify"],
.RichTextEditorContainer div[title="Color Picker"],
.RichTextEditorContainer div[title="Remove"],
.RichTextEditorContainer div[title="Indent"],
.RichTextEditorContainer div[title="Outdent"],
.RichTextEditorContainer div[title="Link"],
.RichTextEditorContainer div[title="Unlink"],
.RichTextEditorContainer div.rdw-fontfamily-dropdown,
.RichTextEditorContainer div.rdw-image-wrapper,
.RichTextEditorContainer div.rdw-fontsize-wrapper,
.RichTextEditorContainer div[title="Monospace"],
.RichTextEditorContainer div[title="Underline"] {
    display: none;
}
.p-slider .p-slider-range,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #720cdfa9!important;
}
.p-slider .p-slider-handle {
    border-color: #710cdf!important;
}

.IdealCandidateProfileQuestion {
    display: block;
    padding: 0.25rem;
}
.IdealCandidateProfileExplainer {
    display: block;
    padding: 5px 0px;
    /* color: #4f6c7b; */
}
.IdealCandidateProfileSlider {
    margin: 12px 0px;
}
.IdealCandidateProfileTable td {
    border: 0;
}
.table.IdealCandidateProgressLabels {
    margin-top: 30px;
    margin-bottom: 0px;
}
.table.IdealCandidateProgressLabels td {
    border-top: 0;
    font-weight: 500;
    color: #4f6c7b;
    padding: 0.25rem;
}
.table.IdealCandidateProgressLabels td span.Active {
    color: #710cdf;
}
.IdealCandidateLead {
    font-size: 16px;
    color: #4E5760;
}
.IdealCandidateValidIcon {
    color: #20c997;
}
.IdealCandidateNonValidIcon {
    color: #842029;
}
.CandidateProfileCard.Active {
    background-color: #390671;
}
.CandidateProfileCard.Active h4 {
    color: #fff;
}
.CandidateProfileCategory {
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    display: block;    
}
.CandidateProfileUse {
    float: left;
}
.CandidateProfileEdit {
    padding: 10px 20px;
    margin-top: 15px;
    display: block;
    float: left;
}
.CandidateProfileCard.Active .CandidateProfileCategory {
    color: #fff;
}

.Bold {
    font-weight: 700;
}
.CandidateSkill {
    margin-right: 4px;
    margin-bottom: 4px;
    background-color: #dee2e6;
    background-color: #f4ecfe;
    color: #495057;
    border-radius: 8px;
    padding: 8px 16px;
    display: block;
}
.CandidateSkillName {
    width: 50%;
    display: inline-block;
}
.CandidateSkillRequired {
    width: 25%;
    display: inline-block;    
    text-align: right;
}
.CandidateSkillScale {
    width: 25%;
    display: inline-block;
    text-align: right;
}
.CandidateModalSkillName {
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    display: block;    
}
.CandidateProfileBox {
    padding-top: 0px;
}
.CandidateProfilePhoto {
    width: 140px;
    height: 140px;
    padding: 7px 0px;
    background: #fff;
    border-radius: 50%;
    border: 4px #fff solid;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    margin: auto;
    margin-top: -100px;
    display: block;
}
.CandidateProfileSide {
    margin: 0px -15px -20px -15px;
    padding: 0;
    overflow: hidden;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}
.CandidateProfileMain {
    padding: 30px;
}
.CandidateProfileMain .row {
    border-left: 2px rgba(120,84,172,1) solid;
    margin-bottom: 30px;
    margin-left: 0px;
    position: relative;
}
.CandidateProfileMain .row::before {
    content: "";
    width: 9px;
    height: 9px;
    display: inline-block;
    position: absolute;
    top: 0px;
    left: -5px;
    background: rgba(120,84,172,1);
    border-radius: 50%;
}

.CandidateProfileSideWhite {
    padding: 30px;
    width: 100%;
    height: 100px;
    background: linear-gradient(90deg, rgba(154,119,202,1) 0%, rgba(120,84,172,1) 54%);
}
.CandidateProfileSidePurple {
    padding: 30px;
    background: rgb(57,7,113);
    background: linear-gradient(45deg, rgba(57,7,113,1) 0%, rgba(85,10,170,1) 35%, rgba(85,10,170,1) 100%);
    background: linear-gradient(90deg, rgba(154,119,202,1) 0%, rgba(120,84,172,1) 54%);
    height: calc(100% - 100px);
}

.CandidateProfileSidePurple h1 {
    margin-bottom: 30px;
    margin-top: 15px;
    text-align: center;
}
.CandidateProfileSidePurple h1,
.CandidateProfileSidePurple h2,
.CandidateProfileSidePurple h3 {
    color: #fff;
}
.RequiredOrIdeal {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    color: #390671;
    font-weight: 500;
    padding: 3px 0px;
    margin-top: 10px;
    border-top: 1px rgba(255,255,255, 0.5) solid;
}
.CandidateProfileSidePurple .RequiredOrIdeal {
    color: #fff;
}
.VideoWrapper {
    border-radius: 24px;
    overflow: hidden;
}
.VideoWrapper video {
    width: 100%;
}
.AllocateSelect {
    margin: -6px 0px;  
}
.AllocateSelect .p-dropdown-label {
    padding: 5px!important;
    font-size: 13px!important;
}
.SingleJobGroup h2 {
    font-size: 28px;
}
.GuaranteeContainer {
    max-width: 100%;
}
.GuaranteeDataUnit {
    margin-top: -25px;
    display: block;
    margin-bottom: 30px;
}
.GuaranteeDataTitle {
    text-transform: uppercase;
    color: #710ce2;
    font-size: 16px;
    font-weight: 700;
    /* color: #390671;
    font-size: 24px; */
}
.GuaranteeDataHighlight {
    display: block;
    color: #390671;
    font-size: 32px;
    font-weight: 800;

}
.GuaranteeContainerBox {
    position: relative;
    background-color: #f4ecfe;
    border: 1px #f4ecfe solid;
    padding: 60px 45px 0px 45px;
    border-radius: 20px;
    margin-bottom: 30px;
    transition: 0.2s ease all;
    overflow: hidden;
    border-radius: 8px;
}
.GuaranteeContainerBox:hover {
    scale: 1;
}

.GuaranteeBox {
    background-color: #20c997;
    text-align: center;
    padding: 60px 60px;
    border-left: 1px #fff solid;
    min-height: 220px;
}
.GuaranteeBox span {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}
.GuaranteeBox span.Highlight {
    color: #fff;
    font-size: 48px;
    font-weight: 700;
    margin-top: -30px;
    margin-bottom: -15px;
}

.GuaranteeBoxNoGuarantee {
    background-color: #842029;
}
.GuaranteeBoxMargin {
    margin: -60px -60px -60px 0px;
}

.SingleJobGroup .p-tabview-title {
    font-size: 14px;
}
.SingleJobGroup .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #710cdf;
    border-color: #710cdf;
    font-weight: 700;
}
.SingleJobGroup .p-component {
    font-family: 'Onest', sans-serif!important;
}
.SingleJobGroup .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #4f6c7b;
    font-weight: 500;
}
.SingleJobGroup .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #710cdf;
}
.SingleJobGroup .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}
.Main.MainAdminFullWidth.container {
    max-width: calc(100% - 64px);
    /* max-width: 100%; */
    margin-left: 64px;
    /* margin-left: 0px; */
    /* background-color: #fff; */
    min-height: 100%;
    /* padding-left: 30px;
    padding-right: 30px; */
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 30px;
    /* padding-top: calc(80px + 30px); */
}
.Centralizer {
    max-width: 1600px;
    margin: auto;
    padding: 15px 20px 20px 20px;
}

.Centralizer.White {
    border-radius: 12px;
    background-color: #fff;
    border: 1px #eff3f8 solid;
	/* box-shadow: 0px 10px 20px rgba(0,0,0,0.05); */
}
.Content {
    max-width: calc(1560px);
    margin: auto;
    padding: 0px;
}
.ContentWhite {
    max-width: calc(1560px);
    margin: auto;
    border-radius: 12px;
    background-color: #fff;
    border: 1px #eff3f8 solid;
    padding: 15px 20px 20px 20px;
}

.MainAdmin {
    background-color: #fff;
}

.AdminContent h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0px 0px 20px 0px;
}

.OrderItemHeader {
    background: #fff;
    width: calc(100%);
    margin-left: 0px;
    padding: 30px;
    height: 120px;
    margin-top: 0px;
}
.OrderItemTitle {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0px;
}
.OrderItemMeta {
    display: inline-block;
    padding: 5px 0px;
    color: #4f6c7b;
    font-size: 14px;
    margin-right: 15px;
}
.OrderItemMeta svg {
    display: inline-block;
    margin-right: 5px;
}
.OrderItemContent {
    width: calc(100% - 64px);
    margin: auto;
    padding: 30px;
}
.AdminNavContainer {
    background: #fff;
    position: absolute;
    top: 80px;
    /* width: calc(100% - 64px); */
    width: 100%;
    left: 0px;
    border-bottom: 1px #eff0f2 solid;
}
.AdminNav {
    list-style: none;
    margin-bottom: 0px;
    max-width: 1800px;
    margin: auto;
}
.AdminNavItem {
    display: inline-block;
}
.AdminNavLink {
    display: block;
    padding: 15px 20px;
    color: #0E70FA;
    font-size: 14px;
}
.OrderItemNav {
    margin-top: 10px;
}
.OrderItemNav .btn {
    font-size: 14px;
}
.InfoBoxAdmin {
    background: #fff;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
    border-radius: 6px;
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 15px;
    text-align: center;
}
.InfoBoxAdmin.Primary {
    background: #710de0!important;
    background: -webkit-linear-gradient(to right, #4A00E0, #710de0)!important;
    background: linear-gradient(to right, #4A00E0, #710de0)!important;   
}
.InfoBoxAdmin.Purple {
    background: #710de0!important;
    background: -webkit-linear-gradient(to right, #4A00E0, #710de0)!important;
    background: linear-gradient(to right, #4A00E0, #710de0)!important;   
}
.MainAdminFullWidth .InfoBoxAdmin {
    border: 1px solid #e0e6e8;
	box-shadow: 0px 6px 10px rgba(0,0,0,0.05);
}
.InfoBoxAdmin h4 {
    font-size: 16px;
    margin-bottom: 0px;
}
.InfoBoxAdmin p {
    font-size: 14px;
    color: #4f6c7b;
}
.InfoBoxAdmin.Primary h4,
.InfoBoxAdmin.Purple h4 {
    color: #fff;
}
.InfoBoxAdmin .Highlight {
    font-size: 28px;
    font-weight: 700;
    display: block;
    clear: both;
}
.InfoBoxAdmin.Primary .Highlight, 
.InfoBoxAdmin.Purple .Highlight {
    color: #fff;
}

.Capitalize {
    text-transform: capitalize;
}
.AdminForm label {
    display: block;
    font-size: 14px;
    color: #4f6c7b;
}
.StatusPill.Success,
.StatusPill {
	display: inline-block;
	padding: 4px 8px;
	background-color: #dfd;
	border-radius: 12px;
	margin: -4px 0px;
}
.StatusPill.None {
    background-color: transparent!important;
}
.StatusPill.available,
.StatusPill.Available {
	background-color: #eeeeee;
	color: #666666;
}
.StatusPill.New,
.StatusPill.New {
	background-color: #fff3cd;
	color: #664d03;
}
.StatusPill.Wait,
.StatusPill.NotSent,
.StatusPill.NotPaid,
.StatusPill.Pending {
    background-color: #f4ecfe;
    color: #710de0;
}
.StatusPill.OK,
.StatusPill.Ready,
.StatusPill.Setup {
	background-color: #cff4fc;
	color: #055160;
}
.StatusPill.Draft {
	background-color: #cff4fc;
	color: #055160;
}
.StatusPill.Active {
	background-color: #20c997;
	color: #fff;
}
.StatusPill.Paid,
.StatusPill.Completed {
	background-color:#d1e7dd;
	color: #0f5132;
}
.StatusPill.Fail,
.StatusPill.NoContract,
.StatusPill.Cancelled {
	background-color: #f8d7da;
	color: #842029;
}




.StatusPillFilters {
    list-style: none;
    font-size: 14px;
}

.StatusPillFilters .NavItem {
    padding: 10px 5px;
    display: inline-block;
}
.StatusPillFilters .NavLink {
    display: block;
    padding: 4px 15px;
    color: #4f6c7b;
    border-radius: 12px;
    background-color: #f8f9fc;
}
.Centralizer .StatusPillFilters .NavLink {
    background-color: #fff;
}
.Centralizer.White .StatusPillFilters .NavLink {
    background-color: #f8f9fc;
}
.StatusPillFilters .NavLink.Active {
    background: #4A00E0;
    background: linear-gradient(90deg, rgba(154,119,202,1) 0%, rgba(120,84,172,1) 54%);
    color: #fff;
}

.StatusPillFilters .NavLink:hover {
    cursor: pointer;
}



.InvoicePdfContainer {
    margin-top: 10px;
}
.InvoicePdf {
    background: #fff;
    border: 1px #e0e6e8 solid;
    border-radius: 6px;
    width: 100%;
    height: 1000px;
    padding: 30px 60px;
    margin-bottom: 15px;
    text-align: left;
}
.InvoicePdfHeader {
    width: 100%;
    height: 200px;
    color: #4f6c7b;
    font-size: 11px;
}
.InvoicePdfPreContent {
    width: 100%;
    height: 200px;
    font-size: 12px;
}
.InvoicePdfTable {
    width: 100%;
    height: 200px;
    font-size: 12px;
}
.InvoicePdfAfterContent {
    width: 100%;
    height: 200px;
    font-size: 12px;
}
.InvoicePdfFooter {
    width: 100%;
    height: 200px;
    color: #4f6c7b;
    font-size: 11px;
}
.InvoicePdfHeader span,
.InvoicePdfFooter span {
        display: block;
}

.InvoiceImg {
    display: block;
    max-width: 120px!important;
    margin-bottom: 15px;
    float: right;
}

.AdminFilter input {
    font-size: 13px;
}


.FormEditorQuestion {
    border: 1px #d1e7dd solid;
    padding: 10px 30px;
    border-radius: 12px;
    margin-bottom: 5px;
}
.FormEditorQuestionOption {
    margin-left: 30px;
}
.OrderItemStatusLabel {
    font-size: 12px;
    color: #4f6c7b;
    margin-top: 0px;
    margin-bottom: 0px;
}
.ClusterCard {
    background-color: #390671;
    background-color: #fff;
    min-height: 128px;
    padding: 20px;
    margin-bottom: 30px;
}
.ClusterCard h4 {
    color: #fff;
    color: #390671;
}
.ClusterCard span {
    color: #390671;
}

.ClusterCardLink:hover {
    text-decoration: none;
}

.WhiteTable {
    background: #fff;
}
.table.TalentInsightsTable {
    font-size: 13px;
}
.TableContainer {
	box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 15px;
    border: 1px #e0e6e8 solid;
}
.TableContainer .table {
    margin-bottom: 0px;
}
.table {
    font-size: 14px;
}
.table.no-borders td {
    border: none;
}
.table.no-borders th {
    font-weight: 700;
}

.table th {
    border-top: 0px!important;
}
.table tr.total {
    background-color: #f8f9fc;
    font-weight: 700;
}

.MainAdminFullWidth .TableContainer {
    box-shadow: none;
    border-radius: 6px;
    border: 1px solid #e0e6e8;
}

.MainAdminFullWidth .table thead th {
    background-color: #f8f9fc;
    border-bottom: 1px solid #e0e6e8;

}
.AdminAnalysisTable {
    font-size: 13px;
}
.AdminAnalysisTable .RecruitmentStatus {
    position: relative;
    font-size: 13px;
    margin: -3px 0px;
    top: 0px;
    left: 0px;
}

.table td .tableSelect, 
.table th .tableSelect {
    margin: -0.25rem 0;

}
.table td .tableSelect .p-multiselect .p-multiselect-label,
.table td .tableSelect .p-cascadeselect .p-cascadeselect-label {
    padding: 0.5rem 0.5rem;
    font-size: 14px;
}

.table td .RangeSelect {
    margin: 0.7rem 0;
}
.TopBar {
    position: relative;
    top: 0;
    margin-top: -15px;
    height: 80px;
    width: calc(100% - 64px);
    margin-left: 64px;
    /* background: #182841; */
    background: transparent;
    /* border-bottom: 1px #eee solid; */
    padding: 0px 30px;
    z-index: 12;
}
.HeaderLogo {
    float: left;
    width: 60px;
    padding: 6px;
    margin-top: 6px;
}
.LoginLogo {
    display: block;
    float: left;
    width: 60px;
    padding: 0px;
    margin: -10px 30px;

}
.HeaderNav {
    list-style: none;
}
.HeaderIconLink {
    display: block;
    padding: 28px 10px;
}
.HeaderNav .HeaderNavItem {
    float: right;
    display: block;
}
.HeaderDivider {
    width: 1px;
    background: rgba(0,0,0,0.1);
    height: 40px;
    margin: 20px 10px;
    float: right;
    display: block;
}

.ProfilePhoto {
    border-radius: 50%;
    border: 1px solid #e0e6e8;
    width: 40px;
    height: 40px;
    margin: 20px -10px 20px 0px;
}
.HeaderNavLink,
.ProfileLink {
    float: right;
    display: block;
    height: 32px;
    line-height: 32px;
    margin: 24px 0px;
    text-align: left;
    color: #4A00E0;
    font-weight: 500;
    padding: 0px 15px;
    position: relative;
    font-size: 14px;
    transition: all 0.2s ease;
}
.HeaderNavLinkJOM {
    border: 1px rgba(255,255,255,0.5) solid;
    border-radius: 16px;
}
.HeaderNavLink svg,
.ProfileLink svg{
    margin-left: 5px;
}
.HeaderNavLink.Active {
    background:rgba(255,255,255,0.4);
    color: #fff;
    border-radius: 15px;
}
.TopBar.White .ProfileLink,
.TopBar.White .HeaderNavLink {
    color: #fff;
}

.HeaderNavLink:hover {
    cursor: pointer;
}
.HeaderNavLink svg {
    line-height: 40px;
}
.TextInput,
.TextArea,
.Select {
	display: block;
	width: 100%;
	height: 40px;
	border-radius: 8px;
    padding: 6px 10px;
	border: 1px #e0e6e8 solid;
	margin-bottom: 15px;
    /* color: #454545; */
    color: #343A40;
    font-size: 13px;
    background: #f8f9fc;

}
.TextInput.p-inputtext {
    background: #f8f9fc;
    font-size: 14px;
    font-family: inherit;
	margin-bottom: 10px;
}
.DropdownInput {
    width: 100%;
    margin-bottom: 10px;
}
.p-dropdown {
    border: none!important;
}
.p-dropdown,
.p-dropdown .p-dropdown-label.p-inputtext {
    background: #f8f9fc;
    font-size: 14px!important;
    font-family: inherit!important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 14px!important;
    font-family: inherit!important;
}
.SelectAllocate {
	display: block;
	width: 100%;
	height: 32px;
	border-radius: 12px;
    padding: 6px 10px;
	border: 1px #e0e6e8 solid;
    color: #343A40;
    font-size: 13px;
    background: #f8f9fc;
    margin: -6px 0px;
}
.TextArea {
    height: 200px;
}
.TextArea.Email {
    height: 100px;
}
.TextInput.form-control {
    font-size: 14px;
}
.FilterLabel {
    color: #4E5760;
    font-size: 12px;
    display: inline-block;
    padding: 0px 0px;
    margin-bottom: 3px;    
}
.RecruitmentSearch .FilterLabel {
    color: #66727e;
    font-weight: 500;
}
.BlockLabel {
    color: #4E5760;
    font-size: 13px;
    display: block;
    padding: 0px 0px;
    margin-bottom: 6px;    
}
.SkillScaleDropdown {
    display: block;
    width: 100%;
    height: 32px;
    border-radius: 8px;
    padding: 3px 10px;
    border: 1px #e0e6e8 solid;
    margin-bottom: 0px;
    font-size: 13px;
    background: #fff;
}
.p-component {
    font-size: 14px!important;
}
.Meridien {
	position: fixed;
	display: block;
    width: 64px;
	height: 100%;
	top: 0px;
	bottom: 0px;
	left: 0px;
    z-index: 16;
	background: #fff;
	/* background: #182841; */
	/* background: #0f0c29;
	background: -webkit-linear-gradient(to bottom, #24243e, #302b63, #182841);
	background: linear-gradient(to bottom, #24243e, #302b63, #182841); */
	/* box-shadow: 0px 10px 20px #DFE2E5; */
	box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
	border-radius: 0px 24px 24px 0px;
    border-right: 1px #e0e6e8 solid;
}
.Meridien .MeridienBottom {
    position: absolute;
    bottom: 0px;
    margin: 0px;
}

.Meridien .logo {
    width: 60px;
    padding: 3px;
    margin: 10px 2px;
    margin-bottom: 30px;
}

.Meridien .nav-bottom {
	position: fixed;
	width: 80px;
	height: calc(2 * 68px);
	display: block;
	/* background: #182841; */
	bottom: 0px;
	left: 0px;
}
.Meridien ul {
    list-style: none;
    padding: 0;

}
.Meridien .NavLink {
    list-style: none;
	padding: 0px;
    display: block;
    margin-bottom: 10px;
}
.Meridien .logo-link:hover {
    cursor: pointer;
}
.Meridien .NavLink span {
	display: block;
	position: relative;
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin: 4px 8px;
	padding: 0px;
	color: #4E5760;
	text-align: left;
	border-radius: 8px;
}
.Meridien .NavLink span:hover {
	/* background-color: rgba(79, 108, 123); */
	background-color: rgba(255,255,255,0.05);
	color: #710de0;
	text-decoration: none;
    cursor: pointer;
}
.Meridien .NavLink.Active span {
	background-color: #f4ecfe;
}
.Meridien .NavLink.Active span svg {
	color: #710de0;
}
.Meridien .NavLink span img {
	display: block;
	height: 64px;
	width: 64px;
	padding: 18px;
}
.Meridien .NavLink span svg {
	font-size: 18px;
    width: 48px;
    line-height: 48px;
    vertical-align: middle;
	border-radius: 50%;
	text-align: center;
	display: inline-block;
}

.Meridien.dark {
    background: #182841;
}
.Meridien.dark .NavLink.Active span {
	background-color: #36b0fb;
}
.Meridien.dark .NavLink.Active span svg {
	color: #fff;
}
.Meridien.dark .NavLink span {
	color: #fff;
}

.Meridien .NavLink span.TooltipText {
	display: none;
	position: absolute;
	top: 0px;
	left: 54px;
	/* background-color: #710de0!important; */
    background: linear-gradient(90deg, rgba(154,119,202,1) 0%, rgba(120,84,172,1) 54%);
	color: #fff;
    width: auto;
	font-size: 14px;
    height: 30px;
	padding: 3px 10px;
    line-height: 24px;
    margin: 10px 8px;
	border-radius: 8px;
    white-space: nowrap;
	transition: 0.3s ease all;
}
.Meridien .NavLink:hover span.TooltipText {
	display: block;
}
.MeridienDivider {
	height: 1px;
	background-color: #f4ecfe;
	width: calc(100% - 16px);
	margin: 8px 8px;
}

.Meridien .NavLink.Profile span img {
	padding: 0px;
    width: 40px;
    height: 40px;
	margin: 4px;
}
.Meridien .NavLink.Profile.Active span {
	background: none;
}
.WebsiteServiceTitle {
    text-transform: uppercase;
    color: #390671;
    color: #710ce2;
    font-size: 16px;
    font-weight: 700;
}
.WebsiteServiceBoxAlternative .WebsiteServiceTitle {
    color: #4A00E0;
}
.WebsiteServiceBoxBg .WebsiteServiceTitle {
    color: #ed79f6;
}
.WebsiteServiceBox h4 {
    color: #390671;
    font-size: 24px;
}
.WebsiteServiceBox h4 span {
    font-size: 36px;
}

.btn.PurpleGradient {
    padding: 8px 20px!important;
    background: linear-gradient(90deg, rgba(154,119,202,1) 0%, rgba(120,84,172,1) 54%);
    color: #fff;
    border: none;
    border-radius: 1rem;
    margin-top: 15px;
    transition: padding 0.25s ease;
}
.btn.PurpleGradientOutline {
    padding: 8px 20px!important;
    background: #fff;
    color: rgba(120,84,172,1);
    border: 1px rgba(120,84,172,1) solid;
    border-radius: 1rem;
    margin-top: 15px;
    transition: padding 0.25s ease;
}
.btn.PurpleGradientOutline:hover {
    color: rgba(120,84,172,1);
    border: 1px rgba(120,84,172,1) solid;
}
.Relative {
    position: relative;
}
.TableHighlight {
    font-weight: 700;
    color: #390671;
}
.iFrame {
    background-color: #fff;
    min-height: 1000px;
    height: 100%;
    border: none;
    border-radius: 4px;
}
.IncubatorInputBox {
    background: #fff;
    padding: 30px;
    /* margin: 0px 30px; */
}
.IncubatorOutputTable td {
    border: none;
    padding: 6px 0px;
}
.IncubatorOutputTable th {
    background-color: #f8f9fc;
    border-radius: 12px;
    padding: 6px 15px;
}
.IncubatorLink {
    color: rgba(120,84,172,1);
}
.IncubatorLink:hover {
    cursor: pointer;
    text-decoration: underline;
}
.border-none {
    border: none!important;
}
.hide {
    display: none;
}
.Square {
    width: 100%;
    aspect-ratio: 1 / 1; /* Ensures the height is always equal to the width */
    margin: 0 auto; /* Center the div */
}
.CampaignBox {
    background: #fff;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
    border-radius: 8px;
    width: 100%;
    padding: 0px;
    margin-bottom: 30px;
    border: 1px #e0e6e8 solid;
    transition: box-shadow 0.25s ease;
    overflow: hidden;
}
.CampaignBox:hover {
	box-shadow: 0px 10px 20px rgba(0,0,0,0.15);
}
.CampaignBoxContent {
    padding: 10px;
}
.CampaignBoxTitle {
    height: 30px;
    overflow-x: hidden;
    color: #343A40;
    line-height: 1.6;
    margin-bottom: 10px;
}
.CampaignBox:hover .CampaignBoxTitle {
    text-decoration: none!important;
}
.CampaignBoxDates {
    font-size: 12px;
    color: #4E5760;
    margin-bottom: 10px;
}
.CampaignCandidateCount {
    background: #91D5A3;
    background: linear-gradient(90deg, rgba(145, 213, 163 , 1) 0%, rgb(113, 183, 132) 84%);
    color: #fff;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    padding: 3px 10px;
    border-radius: 50%;
    position: absolute;
    padding: 0;
    top: -5px;
    right: 5px;
    /* left: calc(40px + 20px + 15px); */
    font-size: 14px;
    font-weight: bold;

}
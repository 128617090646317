.DarkMode {
	/* background: #292943; */
    /* background: hsl(213, 14%, 13%); */
    background: #1b012b;
}
.DarkMode a {
    color: #7753ab;
}
.DarkMode .AdminAnalysisTable a {
    color: #fff;
}
.DarkMode .Main.MainAdminFullWidth.container {
	/* background: #292943; */
    /* background: #1c2025; */
    background: #1b012b;
}

.DarkMode .Meridien {
    /* background: #333349; */
    background: #152e4a;
    background: #28083b;
}
/* .DarkMode .TopBar {
    background: #1c2025;
} */

.DarkMode h1,
.DarkMode h2, 
.DarkMode h3,
.DarkMode .Main h4, 
.DarkMode .MainWithNavigator h4,
.DarkMode .PipelineStage h4,
.DarkMode .Meridien .NavLink span,
.DarkMode .RecruitmentTile .RecruitmentTileTitle,
.DarkMode .Main h2.TalentInsightsCenteredTitle
{
    color: #fff;
}
.DarkMode .Meridien .NavLink.Active span {
    background: #2e445a;
}
.DarkMode .recruitmentFunnelBtn,
.DarkMode .HomeOpen,
.DarkMode .HeaderNavLink, 
.DarkMode .ProfileLink,
.DarkMode .Meridien .NavLink.Active span svg {
    color: #fff;
}


.DarkMode .Box,
.DarkMode .RecruitmentHeader,
.DarkMode .RecruitmentTile,
.DarkMode .RecruitmentFiltersContainer,
.DarkMode .PipelineStage,
.DarkMode .PipelineStage.new
{
    /* background: #333349; */
    background: #152e4a;
    background: #28083b;
    /* background: #480b8b; */
}
.DarkMode .RecruitmentHeader,
.DarkMode .RecruitmentFiltersContainer {
    border-color: #292943;
}


.DarkMode .AssignedExplanation,
.DarkMode .RecruitmentMeta {
    color: #83a0af;
}
.DarkMode .progress {
    background: #1c2025;
}

.DarkMode .TextInput,
.DarkMode .Input,
.DarkMode .Select {
    border-color: #2e445a;
    background: #2e445a;
    background: linear-gradient(90deg, rgba(154,119,202,1) 0%, rgba(120,84,172,1) 54%);
    color: #eee;
}
.DarkMode .CandidateCard {
    border-color: #2e445a;
    background: #152e4a;
}

.DarkMode .SideOptionsToggle,
.DarkMode .SideOptionsContent,
.DarkMode .SideOptions {
    /* background: #292943; */
    background: #152e4a;
    color: #fff;
}

.DarkMode .table {
    /* background: #333349; */
    background: #2e445a;
    background: #28083b;
    color: #eee;
    border-color: #1b012b;
}
.DarkMode .table thead th,
.DarkMode .table td {
    /* border-color: #83a0af; */
    border-color: #152e4a;
    border-color: #1b012b;
}
.DarkMode .table tr.total {
    background-color: #333349;
    font-weight: 700;
}
.DarkMode .TableContainer,
.DarkMode .MainAdminFullWidth .table thead th {
    /* background-color: #333349; */
    background-color: #152e4a;
    background-color: #1b012b;
}

.DarkMode .MainAdminFullWidth .TableContainer,
.DarkMode .MainAdminFullWidth .table thead th {
    /* border-color: #4f6c7b; */
    border-color: #152e4a;
    border-color: #1b012b;
    background-color: #1b012b;
}
.DarkMode .RoleCard {
    background-color: #333349;
    color: #eee;
}

.DarkMode .ExplorerContent p {
    color: #83a0af;
}
.DarkMode .Centralizer.White,
.DarkMode .InfoBox,
.DarkMode .InfoBoxAdmin {
    /* background-color: #333349;
    border-color: #4f6c7b; */
    color: #eee;
    background-color: #152e4a;
    border-color: #152e4a;
    background: #28083b;
    border-color: #28083b;
    
}


.DarkMode .btn.RecruitmentNavLink {
    background: #2e445a;
    color: #fff;
}
.DarkMode .btn.RecruitmentNavLink.Active {
    background: #1c2025;
}

.DarkMode .Navigator {
    background: #152e4a;
}
.DarkMode .Navigator .NavLink {
    color: #eee;
}
.DarkMode .Navigator .NavLink.Active {
    background: #2e445a;
    color: #eee;
}

.DarkMode .TalentInsightsContentBox,
.DarkMode .Meridien,
.DarkMode .RecruitmentTile,
.DarkMode .TableContainer,
.DarkMode .Box {
    border-color: #292943;
    border-color: #28083b;
    border-color: #1b012b;
}
.DarkMode .TalentInsightsContentBox,
.DarkMode .p-tabview .p-tabview-nav,
.DarkMode .p-tabview .p-tabview-panels,
.DarkMode .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: #28083b;
}
/* .DarkMode .TalentInsightsContentBox {
    background: #4e089bff;
} */
.DarkMode .StatusPillFilters .NavLink {
    background: #1b012b;
    color: #dee2e6;
}
.DarkMode .StatusPillFilters .NavLink.Active {
    background: #7753ab;
}

/* LAPTOPS */
@media only screen and (max-width: 1500px) {
    .Main.container {
        margin: 0px 0px 0px 64px;
        max-width: calc(100% - 64px);
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }    
    .HeroHome .row {
        margin: 0px 0px 0px 64px;
        max-width: calc(100% - 64px);
    }
}
@media only screen and (max-width: 1264px) {
    .Main.container {
        margin: 0px 0px 0px 64px;
        max-width: calc(100% - 64px);
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }    
    .HeroHome .row {
        margin: 0px 0px 0px 64px;
        max-width: calc(100% - 64px);
    }
}
/* MOBILE */
@media only screen and (max-width: 600px) {
	body {
        zoom: 1;
	}
    .HeroHome .row {
        margin: 0px 0px 0px 0px;
        max-width: calc(100%);
        padding: 0px;
    }    
    .HeroHome h2 {
        padding: 0;
        margin: 0;
    }
    .HeroHomeProfilePhoto {
        display: none;
    }
    .HeroLead {
        padding: 0px;
        margin: 0px;
    }
    .MobileHidden {
        display: none!important;
    }
    header {
        width: 100%;
        margin-left: 0px;
        padding: 0px 10px;
        height: 60px;
        /* background: #f8f9fc; */
    }
    .TopBar {
        margin-left: 0px;
        width: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
    .TopBar .Centralizer {
        padding: 0 15px;
    }
    .HeaderNav {
        position: absolute;
        top: 0;
    }
    .HeaderNav .HeaderDivider {
        display: none;
    }
    .HeaderNav .HeaderNavItem {
        float: right;
    }
    .HeaderNav .HeaderNavItem.mobile-right {
        float: right;
    }
    .ProfilePhoto {
        border: 0px;
        margin: 10px 5px;
    }
    .ProfileLink {
        margin: 0px 5px;
        padding: 10px 5px 10px 0px;
    }    
    .HeaderDivider {
        margin: 10px 5px;
    }    
    .HeaderNavLink {
        margin: 0px 5px;
        padding: 10px 5px;
    }
    .CampaignsAvailable, 
    .HeroHome h2 {
        font-size: 21px;
        padding: 15px 0px;
    }
    .HomeOpens {
        margin-left: 0px;
        display: inline-block;
        text-align: left;
    }
    .HomeOpen {
        margin-bottom: 5px;
    }
    #home {
        margin-top: 80px;
    }
    .home-content {
        margin-top: -15px;
    }
    .Main {
        max-width: 100%!important;
        margin-left: 0px!important;
        padding: 0px 10px;
        padding-bottom: calc(80px + 15px);
    }
    .Main.container {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
    }    
    .MainWithNavigator.container {
        max-width: calc(100% - 200px);
        margin-left: calc(0px + 200px);
        padding: 0px 10px;
    }
    .Main.MainAdminFullWidth.container {
        max-width: 100%!important;
        margin-left: 0px!important;
        padding: 0px 0px;

    }
    .Main h1, 
    .MainWithNavigator h1 {
        display: none;
    }
    .Main h2, .MainWithNavigator h2 {
        font-size: 20px;
        margin: 10px 0px;
    }
    .Main h2.TalentInsightsCenteredTitle {
        font-size: 24px;
        margin: 10px 0px;
    }
    .TalentInsightsContentBox h4.TalentInsightBoxCentered {
        margin-bottom: 15px;
    }
    .TalentInsightsContentBox h4 {
        font-size: 20px;
    }
    .ProductVariationBox, .TalentInsightsContentBox {
        padding: 15px;
    }
    .Centralizer {
        padding: 15px;
    }
    .Centralizer.White {
        border: none;
        background: transparent;
    }
    .Box.TalentInsightsBox h2 {
        font-size: 24px;
    }
    .main-view {
        margin-top: 60px;
        padding: 20px 0px;
    }
    .main-dropdown {
        width: 95%;
        left: 2.5%;
        top: 60px;
    }
    .info-box-admin.hero.purple {
        position: fixed;
        top: -100px;
        width: 100%;
        left: 0px;
        transform: rotate(0deg);
        height: 340px;
        border-radius: 0px;
    }
    .FullWidth.container {
        max-width: 100%;
        margin-left: 0px;
    }
    .RecruitmentHeader {
        background: #fff;
        width: 100%;
        margin-left: 0px;
        padding: 20px 15px 10px 15px;
        height: 140px;
        margin-top: 0px;
    }
    #recruitment-header .btn-settings {
        margin-top: 10px;
        font-size: 13px;
        float: left;
    }
    #recruitment-header .recruitment-status {
        margin-right: 10px;
    }    
    #recruitment-filters {
        width: calc(100%);
        margin-left: 0px;
        padding: 5px 15px;
        height: 50px;
    }
    .department, .location {
        font-size: 13px;
        margin-right: 10px;
    }
    .category-filters {
        width: 100%;
    }
        
    .assignee-photo {
        height: 34px;
    }
    .tooltiptext {
        display: none!important;
    }
    .Meridien {
		width: 100%;
        height: 74px;
		top: auto;
		bottom: 0px;
		left: 0px;
        border-radius: 24px 24px 0px 0px;
        padding: 0px 10px 10px 10px;
        box-shadow: -3px 0px 10px rgba(0,0,0,0.05);
	}
	.Meridien.large {
		width: 100%;
	}
	.Meridien.large .large-sidebar-title {
		display: none;
	}
    .NavLogoItem,
	.Meridien .nav-bottom,
	.Meridien .sign-out,
	.Meridien .nav-logo-item {
		display: none;
	}
    .Meridien .logo {
        width: 60px;
        padding: 10px 12px 14px 12px;
        margin: 2px 0px;
        margin-bottom: 0px;
    }
	.Meridien .NavItem {
		width: 16.66%;
        float: left;
        text-align: center;
        margin-top: 2px;
	}
	.Meridien .NavLink span {
		text-align: center;
        margin: 6px auto;
	}	
    .Meridien .TooltipText {
        display: none!important;
    }
    .MeridienBottom {
        display: none;
    }
    .candidate-pool .candidate-card {
        width: 100%;
        margin-right: 0px;
    }
    li.stage-container {
        min-width: 240px;
        max-width: 100%;
        width: 100%;
    }     
    .side-navigator,
    .Navigator {
        padding-left: calc(15px);
        width: 200px;
        left: 0px;
    }
    .home-intro h2 {
        font-size: 24px;
        padding: 30px 20px;
    }
    .recruitment-tile {
        padding: 15px 20px;
    }
    .recruitment-tile .recruitment-title {
        font-size: 16px;
        margin: 5px 0px;
    }
    .recruitment-status {
        font-size: 13px;
        left: 20px;
    }
    .price {
        font-size: 14px;
    }
    .main-stages {
        overflow-x: auto;
        overflow-y: auto;
    }
    .stages {
        width: 100%;
        white-space: auto;       
    }
    .PipelineStage {
        display: block;
        min-height: 0px;
        height: auto;
    }
    .InfoBox {
        width: 100%;
        padding: 15px 20px;
        text-align: left;
    }
    .InfoBox h4 {
        font-size: 16px;
        margin: 0;
    }
    .InfoBox .btn {
        margin-top: 0px;
    }
    .Box {
        padding: 15px 20px;
    }
    .Box.text-center {
        text-align: left!important;
    }
    .Box p {
        margin-bottom: 0.25rem;
    }
    .CandidateModal {
        position: fixed;
        top: 60px;
        left: 2.5%;
        z-index: 19;
        width: 95%;
        max-width: 95%;
        margin: 0px auto;
    }
    .CandidateModalMain {
        padding: 0px 0px;
    }
        
    .candidate-profile-header {
        padding: 20px 20px;
        height: 160px;
    }    
    .candidate-profile-filters {
        height: 50px;
    }    
    h4.candidate-name {
        font-size: 18px;
    }    
    .candidate-profile-department, .candidate-profile-location, .candidate-profile-recruitment-title {
        font-size: 14px;
    }     
    .timeline-filters {
        width: 100%;
        overflow-x:scroll;
        overflow-y:hidden;
        padding: 0px 10px;
        height: 50px;
        width: max-content;
        white-space:nowrap;       
    }
    
    .timeline-filters .nav-item {
        padding: 8px 10px;
        float: left;
    }
    .candidate-profile-main {
        background: transparent;
        height: auto;
        padding: 0px 0px;
    }
    #profile-actions,
    #profile-content {
        background: transparent;
        border-radius: 0px;
        height: auto;
        max-height: auto;
        min-height: auto;
        overflow-y: scroll;
        padding: 20px;
        box-shadow: none;
        width: calc(100%);
        /* margin: 0px 30px; */
    }
    .candidate-profile-content {
        height: auto;
    }

    .TableContainer {
        overflow-x: scroll;
    }
    .RecruitmentIdealCandidate {
        display: none;
    }
    .RecruitmentPipeline {
        max-width: 100%;
        left: 0;
    }
    .RecruitmentTileBackground {
        margin: -15px -15px 15px -15px;
   
    }
    .AdminFilters {
        display: block;
        overflow-x: auto;
    }
    .StatusPillFilters {
        display: flex; /* Flexbox allows the items to be arranged horizontally */
        padding: 0;
        margin:  0 0 10px 0px;
        list-style-type: none;    
    }
    .StatusPillFilters .NavItem {
        flex: 0 0 auto; /* Prevents shrinking, ensuring items keep their size */
        float: left;
        margin: 0;
        margin-right: 3px; /* Add space between items */
        padding: 3px 0px;
    }
    
    .StatusPillFilters::-webkit-scrollbar {
        display: none; /* Hide scrollbar in Webkit-based browsers (optional) */
    }
    .ClusterCard {
        margin-bottom: 10px;
    }
    .btn.PurpleGradient {
        margin-top: 5px;
        padding: 6px 15px!important;
        font-size: 0.85rem;
        border-radius: 0.85rem;
    }
}